import { gpuHubCnf } from '@/constant/config.js';
import axios from "axios";
const queryString = require('query-string');
export default {
    getElementsList(pageNumber, requestParam) {
        let queryParams = queryString.stringify(Object.assign({
            queryAction: requestParam.queryAction,
            querySearch: requestParam.querySearch,
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
        }, requestParam.filterItem));

        return axios({
            method: "GET",
            url: `${gpuHubCnf.getListInvoice}/${requestParam.ownedTarget !== null ? requestParam.ownedTarget : ''}?${queryParams}`,
            withCredentials: true,
        });
    },
    getInvoiceInitedByUser(pageNumber, requestParam) {
        let queryParams = queryString.stringify(Object.assign({
            pageIndex: pageNumber,
            pageSize: requestParam.pageSize,
        }));

        return axios({
            method: "GET",
            url: `${gpuHubCnf.getInvoiceInitedByUser}/${requestParam.userId}?${queryParams}`,
            withCredentials: true,
        });
    },
    trackInvoice(invoiceId) {
        return axios({
            method: "GET",
            url: `${gpuHubCnf.trackInvoice.format(invoiceId)}`,
            withCredentials: true,
        });
    },
    verifyPayment(invoiceId) {
        return axios({
            method: "GET",
            url: `${gpuHubCnf.verifyPayment.format(invoiceId)}`,
            withCredentials: true,
        });
    }
};
<template>
    <div>
        <fieldset style="border: 1px solid transparent; border-radius: 5px;"
                  class="p-3 pt-0 pt-lg-2 pb-2">
            <div class="d-none d-lg-block">
                <h4><strong class="text-white"><i class="el-icon-money text-warning"></i> Manual recharge</strong></h4>
            </div>
            <div class="custom-control custom-radio d-none mr-3 mb-3">
                <input class="custom-control-input"
                       type="radio"
                       id="gpuHub"
                       value="gpuHub"
                       checked
                       v-model="rechargeModel.pickedSource" />
                <label for="gpuHub" class="custom-control-label">Gpu Hub</label>
            </div>
            <!--<div class="custom-control custom-radio d-inline-block">
                                                <input class="custom-control-input" type="radio" id="renderFarm" value="renderFarm" v-model="rechargeModel.pickedSource">
                                                <label for="renderFarm" class="custom-control-label">Render farm</label>
            </div>-->

            <div class="mt-0 mt-lg-2">
                <div>
                    <div class="flex-fill">
                        <el-card class="align-items-center" :body-style="{ padding: '0.5rem 0.8rem' }">
                            <div class="text-nowrap mb-2 position-relative d-none d-lg-inline-block">
                                <strong>Select an option</strong>
                            </div>
                            <div class="flex-fill">
                                <div class="d-flex align-items-center">
                                    <div class="flex-fill text-nowrap">                                        
                                        <el-radio-group v-model="rechargeOption" size="small">
                                            <el-radio-button label="fromPaymentInvoice" v-if="checkAccess('USER_RECHARGE_BALANCE')"><i class="el-icon-document"></i> Invoice</el-radio-button>
                                            <el-radio-button label="manual"><i class="el-icon-thumb"></i> Input manual</el-radio-button>
                                        </el-radio-group>
                                    </div>
                                    <div v-if="(rechargeOption === 'fromPaymentInvoice' && paymentInvoiceSelected === null)"
                                         class="mb-2 text-nowrap">                                       
                                        <button type="button" class="btn bg-gradient-primary btn-sm" @click="refreshClick">
                                            <i :class="`fas fa-sync-alt`"></i> <span class="d-none d-lg-inline">&nbsp;Refresh</span>
                                        </button>
                                        <button type="button"
                                                class="btn btn-sm bg-gradient-danger text-nowrap ml-2"
                                                @click="closeRecharge()">
                                            <i class="fas fa-times"></i>
                                        </button> 
                                    </div>
                                </div>
                                <div class="mt-1 mb-1" v-if="rechargeOption === 'fromPaymentInvoice'">
                                    <el-card class="" :body-style="{ padding: '0'}" 
                                             v-if="paymentInvoiceSelected !== null">
                                        <div class="d-flex align-items-center" style="padding: 0.3rem 0.5rem;">
                                            <div class="flex-fill overflow-hidden">                                                        
                                                 <div><strong>Invoice Id:</strong> {{rechargeModel.invoiceId}}</div>
                                                <div class="text-nowrap"><strong><strong>{{paymentInvoiceSelected.paymentId}} </strong></strong></div>
                                                <div>
                                                    <span class="mr-3">
                                                        <el-tooltip content="Amount to pay" placement="bottom" effect="light">
                                                            <span><i class="el-icon-money text-muted"></i> <strong>${{paymentInvoiceSelected.amountToPay | numFormat('0,0[.][0000]')}}</strong></span>
                                                        </el-tooltip>
                                                    </span>
                                                    <span class="mr-3">
                                                        <el-tooltip content="Main point conversion" placement="bottom" effect="light">
                                                            <span><i class="el-icon-coin text-muted"></i> <strong>{{paymentInvoiceSelected.irenderPoint | numFormat('0,0[.][0000]')}} {{$pointNameShort}}</strong></span>
                                                        </el-tooltip>
                                                    </span>    
                                                    <span class="mr-3">
                                                        <el-tooltip content="Extra point conversion" placement="bottom" effect="light">
                                                            <span><i class="el-icon-coin text-muted"></i> <strong>{{paymentInvoiceSelected.creditPoint | numFormat('0,0[.][0000]')}} {{$pointNameShort}}</strong></span>
                                                        </el-tooltip>
                                                    </span>                                                      
                                                    <span class="text-muted">
                                                        <el-tooltip content="Invoice date" placement="bottom" effect="light">
                                                            <span><i class="el-icon-date text-muted"></i> {{paymentInvoiceSelected.invoiceDate | pretyDateUtc}}</span>
                                                        </el-tooltip>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="ml-2 mr-4">
                                                <img :src="`/img/${paymentInvoiceSelected.paymentInstrumentType}.svg`" style="height: 1.2rem;">
                                            </div>
                                            <div class="mr-1">
                                                <el-tooltip content="Change payment invoice" placement="bottom" effect="light">
                                                    <el-button type="primary" icon="el-icon-edit" circle 
                                                               @click="clearSelectedInvoice"></el-button>
                                                </el-tooltip>
                                            </div>
                                        </div>
                                    </el-card>                                    
                                    <div v-else>
                                        <div v-if="paymentInvoiceSelection.data.length > 0">
                                            <VuePerfectScrollbar  class="w-100" :style="`height: calc(100dvh - ${$root.windowWidth > 1000 ? 25 : 24}rem);`">
                                                <div class="w-100">
                                                    <el-card :body-style="{ padding: '0' }" class="mb-2"
                                                             :key="element.id" v-for="element in paymentInvoiceSelection.data">
                                                        <div class="d-block d-md-flex align-items-center" style="padding: 0.3rem 0.5rem;">
                                                            <div class="flex-fill overflow-hidden">
                                                                <div class="text-nowrap"><strong><strong>{{element.paymentId}} </strong></strong></div>
                                                                <div>
                                                                    <span class="mr-3">
                                                                        <el-tooltip content="Amount to pay" placement="bottom" effect="light">
                                                                            <span><i class="el-icon-money text-muted"></i> <strong>${{element.amountToPay | numFormat('0,0[.][0000]')}}</strong></span>
                                                                        </el-tooltip>
                                                                    </span>
                                                                    <span class="mr-3">
                                                                        <el-tooltip content="Main point conversion" placement="bottom" effect="light">
                                                                            <span><i class="el-icon-coin text-muted"></i> <strong>{{element.irenderPoint | numFormat('0,0[.][0000]')}} {{$pointNameShort}}</strong></span>
                                                                        </el-tooltip>
                                                                    </span>
                                                                    <span class="mr-3">
                                                                        <el-tooltip content="Extra point conversion" placement="bottom" effect="light">
                                                                            <span><i class="el-icon-coin text-muted"></i> <strong>{{element.creditPoint | numFormat('0,0[.][0000]')}} {{$pointNameShort}}</strong></span>
                                                                        </el-tooltip>
                                                                    </span>
                                                                    <span class="text-muted">
                                                                        <el-tooltip content="Invoice date" placement="bottom" effect="light">
                                                                            <span><i class="el-icon-date text-muted"></i> {{element.invoiceDate | pretyDateUtc}}</span>
                                                                        </el-tooltip>
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <small :class="`text-${element.paymentStatusLabelColor} text-capitalize`">
                                                                        {{element.paymentStatusText}}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex align-items-center">
                                                                <div class="ml-0 ml-md-2 mr-4 flex-fill">
                                                                    <img :src="`/img/${element.paymentInstrumentType}.svg`" style="height: 1.2rem;">
                                                                </div>
                                                                <div class="mr-1">
                                                                    <el-tooltip content="Select this payment invoice" placement="bottom" effect="light">
                                                                        <el-button type="primary" icon="el-icon-position" circle @click="selectedInvoiced(element)"></el-button>
                                                                    </el-tooltip>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </el-card>
                                                </div>
                                            </VuePerfectScrollbar>

                                            <div class="mt-2 mb-0 mr-1 d-flex align-content-center justify-content-center align-items-center">
                                                <div class="flex-fill pl-1">
                                                    <h4>{{paymentInvoiceSelection.pagingItem.outRowsNumber}} {{paymentInvoiceSelection.pagingItem.outRowsNumber > 1 ? "items" : "item"}}</h4>
                                                </div>
                                                <div class="d-flex align-content-center justify-content-center align-items-center">
                                                    <paginate v-model="paymentInvoiceSelection.pagingItem.pageIndex"
                                                              :page-count="paymentInvoiceSelection.pagingItem.numberOfPage"
                                                              :page-range="5"
                                                              :click-handler="pageClickHandle"
                                                              :prev-text="'Prev'"
                                                              :next-text="'Next'"
                                                              container-class="pagination pagination-sm mb-0"
                                                              page-class="paginate_button page-item"
                                                              prev-class="paginate_button page-item"
                                                              next-class="paginate_button page-item"
                                                              page-link-class="page-link"
                                                              prev-link-class="page-link"
                                                              next-link-class="page-link">
                                                    </paginate>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="nodata-container flex-fill d-flex align-items-center justify-content-center h-100">
                                            <div class="align-self-center text-center">
                                                <div class="mb-2"><img src="/static/image/no-job-icon.svg" style="opacity: 0.4; max-height: 300px;"></div>
                                                <h4><strong class="text-muted">There is no payment invoice suitable conditions</strong></h4>
                                                <p class="text-muted">All payment invoice suitable conditions will be show here !</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-card>
                        <div v-if="rechargeOption === 'manual' || (rechargeOption === 'fromPaymentInvoice' && paymentInvoiceSelected !== null)">
                            <div class="w-100">
                                <el-card class="mt-1 mt-lg-2 align-items-center" :body-style="{ padding: '0.5rem 0.8rem' }">
                                    <div class="d-flex pb-2">
                                        <div class="flex-fill">
                                            <div class="align-items-center">
                                                <div class="mb-2 d-none d-lg-inline-block">
                                                    <strong>Select target provider</strong>
                                                </div>
                                                <div class="flex-fill">
                                                    <el-select v-model="rechargeModel.provider" placeholder="--Choose--"
                                                               :disabled="paymentInvoiceSelected !== null"
                                                               @change="updatePoint" class="w-100">
                                                        <el-option v-for="item in providerSelection"
                                                                   :key="item.id"
                                                                   :label="item.text"
                                                                   :value="item.text">
                                                        </el-option>
                                                    </el-select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="ml-4" v-if="rechargeModel.provider === 'COUPON'">
                                            <div class="align-items-center">
                                                <div class="mb-2">
                                                    <strong>Allowed booting</strong>
                                                </div>
                                                <div class="flex-fill text-right pt-1">
                                                    <el-switch v-model="rechargeModel.allowedBooting" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </el-card>
                                <el-card class="mt-1 mt-lg-2 align-items-center" :body-style="{ padding: '0.5rem 0.8rem' }">
                                    <div class="text-nowrap mb-2">
                                        <strong>Input Amount ($)</strong>
                                    </div>
                                    <div class="flex-fill">
                                        <el-input-number v-model="rechargeModel.amount"
                                                         :precision="4"
                                                         :step="1"
                                                         :max="100000"
                                                         size="small"
                                                         class="w-100"
                                                         @input="updatePoint"
                                                         :disabled="paymentInvoiceSelected !== null || !providerMap[rechargeModel.provider].isTargetMainBalance"
                                                         @change="updatePoint"></el-input-number>
                                    </div>
                                    <div>
                                        <span class="text-muted mr-2 d-none d-lg-inline-block"><i class="el-icon-thumb"></i> Preset amount: </span>
                                        <el-button :key="prefixAmountVal" v-for="prefixAmountVal in prefixAmount" type="text"
                                                   class="pt-1 pb-1"
                                                   @click="rechargeModel.amount = prefixAmountVal">
                                            ${{prefixAmountVal}}
                                        </el-button>
                                    </div>

                                    <div class="mt-0 mt-lg-2">
                                        <div class="flex-fill d-flex flex-column flex-lg-row w-100">
                                            <div class="flex-fill mt-2 mt-lg-0">
                                                <label>Main balance</label>
                                                <div>
                                                    <el-input-number v-model="rechargeModel.pointConversion"
                                                                     :precision="4"
                                                                     :step="1"
                                                                     :max="100000"
                                                                     size="small"
                                                                     :disabled="paymentInvoiceSelected !== null || (!providerMap[rechargeModel.provider].isTargetMainBalance && !isQaOrOwned)"
                                                                     class="w-100"></el-input-number>
                                                </div>
                                                <div>
                                                    <span class="text-muted mr-2 d-none d-lg-inline-block">Preset </span>
                                                    <el-button :key="prefixAmountVal" v-for="prefixAmountVal in prefixAmount" type="text"
                                                                class="pt-1 pb-1"
                                                               @click="rechargeModel.pointConversion = prefixAmountVal">
                                                        ${{prefixAmountVal}}
                                                    </el-button>
                                                </div>
                                            </div>
                                            <div class="ml-4"></div>
                                            <div class="flex-fill mt-2 mt-lg-0">
                                                <label>Extra balance</label>
                                                <div>
                                                    <el-input-number v-model="rechargeModel.extraPointConversion"
                                                                     :precision="4"
                                                                     :step="1"
                                                                     :max="100000"
                                                                     size="small"
                                                                     :disabled="paymentInvoiceSelected !== null"
                                                                     class="w-100"></el-input-number>
                                                </div>
                                                <div>
                                                    <span class="text-muted mr-2 d-none d-lg-inline-block">Preset </span>
                                                    <el-button :key="prefixAmountVal" v-for="prefixAmountVal in prefixAmount" type="text"
                                                                class="pt-1 pb-1"
                                                               @click="rechargeModel.extraPointConversion = prefixAmountVal">
                                                        ${{prefixAmountVal}}
                                                    </el-button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style="width: 8rem;" class="mb-2 mt-0 mt-lg-1">
                                        <strong>Input note</strong>
                                    </div>
                                    <div class="flex-fill mb-2">
                                        <el-input placeholder="Please input note.."
                                                  v-model="rechargeModel.reason"
                                                  clearable></el-input>
                                    </div>
                                </el-card>
                            </div>
                        </div>
                    </div>
                    <el-card class="mt-2 " :body-style="{ padding: '0.5rem 0.8rem' }"
                             v-if="rechargeOption === 'manual' || (rechargeOption === 'fromPaymentInvoice' && paymentInvoiceSelected !== null)">
                        <div class="d-flex justify-content-between">                            
                            <button type="button"
                                    class="btn btn-lg btn-default mr-3 text-nowrap"
                                    @click="closeRecharge()">
                                <i class="fas fa-times"></i> Close
                            </button>
                            <button type="button"
                                    class="btn btn-lg bg-gradient-primary mr-0 text-nowrap cus-animate"
                                    :disabled="rechargeModel.pointConversion <= 0 && rechargeModel.extraPointConversion <= 0"
                                    @click="rechargeBalance()">
                                <!--<i class="far fa-thumbs-up"></i> <strong>Let's go</strong>-->
                                <div></div>
                            </button>
                        </div>
                    </el-card>
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script>
    import usersApi from "@/api/common/users";
    import gpuHubInvoiceApi from '@/api/gpuHub/gpuHubInvoice';
    import commonAct from "@/commonActionHandle.js";
    import baseComponent from "@/scripts/baseComponent";
    import { mapState } from "vuex";
    import moment from 'moment';
    import { providerSelection } from "@/constant/config";
    export default {
        extends: baseComponent,
        props: {
            detailUserItem: {
                type: Object,
                default() {
                    return null;
                },
            },
        },
        watch: {
            detailUserItem(newVal) {
                if (this.detailUserItem !== null) {
                    this.updateProviderSelection();
                }
            },
            rechargeOption(newVal) {
                if (newVal === 'manual') this.clearSelectedInvoice();
            }
        },
        created() {
            this.updateProviderSelection();
            this.getInvoiceInitedByUser(1);
        },
        data() {
            return {
                rechargeOption: "manual",
                paymentInvoiceSelected: null,
                paymentInvoiceSelection: {
                    data: [],
                    pagingItem: {
                        pageIndex: 1,
                        pageSize: 20,
                        numberOfPage: 1,
                        outRowsNumber: 0,
                        orderBy: "created_at",
                        directionSort: "desc"
                    }
                },
                rechargeModel: {
                    pickedSource: "gpuHub",
                    amount: 0.0,
                    pointConversion: 0.0,
                    extraPointConversion: 0.0,
                    provider: "TESTING",
                    allowedBooting: false,
                    userId: this.detailUserItem.id,
                },
                providerSelection: providerSelection,
                prefixAmount: [5, 10, 25, 50, 100, 150, 200]
            }
        },
        computed: {
            ...mapState({
                userInfo: (state) => state.auth.user,
                rolesInfo: (state) => state.auth.roles,
                staffsInfo: (state) => state.staffs,
                lowBalanceUser: (state) => state.lowBalanceUser,
                $emailAllowedChangeSource: (state) => state.emailAllowedChangeSource,
            }),  
            isQaOrOwned() {
                return this.rolesInfo.includes('QA') || this.rolesInfo.includes('OWNED') || this.userInfo.userEmail ==='yendph@irender.vn'
            },
            defaultPagingItem() {
                return {
                    pageIndex: 1,
                    pageSize: 20,
                    numberOfPage: 1,
                    outRowsNumber: 0,
                    orderBy: "created_at",
                    directionSort: "desc"
                };
            },
            requestParam() {
                return {
                    userId : this.detailUserItem.id,
                    pageSize: this.paymentInvoiceSelection.pagingItem.pageSize
                };
            },
            isTargetMainBalance() {
                let foundedItem = this.providerSelection.find(
                    (x) => x.id === this.rechargeModel.provider
                );
                return foundedItem.isTargetMainBalance;
            },
            providerMap() {
                return this.providerSelection.reduce(function(acc, cur, i) {
                  acc[cur.id] = cur;
                  return acc;
                }, {});
            }
        },
        methods: {
            updateProviderSelection() {
                let providerSelectionResult = [];
                if (this.checkAccess('USER_RECHARGE_TEST')) {
                    providerSelectionResult.push(providerSelection.filter(x => {
                        return !x.isTargetMainBalance;
                    }));
                }

                if (this.checkAccess('USER_RECHARGE_BALANCE')) {
                    providerSelectionResult.push(providerSelection.filter(x => {
                        return x.isTargetMainBalance;
                    }));
                }

                //if (!this.rolesInfo.includes("QA")) {                    
                //    let allowedIfNotQA = ["TESTING", "BANKING", "PAYONEER", "REFUND", "BONUS", "PAYPAL"];
                //    this.providerSelection = providerSelection.filter(x => {
                //        return allowedIfNotQA.includes(x.id);
                //    });
                //}
            },
            rechargeBalance() {
                commonAct.showConfirm(
                    `Recharge account balance for user <strong>${this.detailUserItem.fullName}</strong>,
                                        Amount <strong>$${this.rechargeModel.amount}</strong>
                                        Main balance point <strong>${this.rechargeModel.pointConversion}${this.$pointNameShort}</strong>, 
                                        Extra balance point <strong>${this.rechargeModel.extraPointConversion}${this.$pointNameShort}</strong>, 
                                        Provider: <strong>${this.rechargeModel.provider} ?`,
                    () => {
                        this.showLoading();
                        this.rechargeModel.userId = this.detailUserItem.id;
                        this.rechargeModel.deviceInformation = JSON.stringify(
                            this.getInformationClient()
                        );
                        usersApi
                            .rechargeBalance(this.rechargeModel)
                            .then((response) => {
                                if (response.data && response.data.result === 0) {
                                    this.hideLoading();
                                    this.showSuccessToast(
                                        "Recharge account balance has been success"
                                    );
                                    if (this.rechargeModel.pickedSource === "gpuHub") {
                                        this.detailUserItem.gpuHubAccountBalance += this.rechargeModel.pointConversion;
                                        this.detailUserItem.gpuHubExtraBalance += this.rechargeModel.extraPointConversion;
                                    }
                                    this.$emit("closeRecharge");
                                } else {
                                    this.hideLoading();
                                    if (
                                        response.data.message !== null &&
                                        response.data.message !== ""
                                    ) {
                                        commonAct.showError(
                                            response.data.message || this.$lang.common.error
                                        );
                                    }
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                                this.hideLoading();
                                commonAct.showError(error);
                            });
                    }
                );
            },
            updatePoint() {
                if (this.isTargetMainBalance) {
                    this.$set(this.rechargeModel, "extraPointConversion", 0);
                    this.$set(this.rechargeModel, "pointConversion", this.rechargeModel.amount);
                }
                else {
                    this.$set(this.rechargeModel, "pointConversion", 0);
                    this.$set(this.rechargeModel, "extraPointConversion", this.rechargeModel.amount);
                }
            },
            closeRecharge() {
                this.$emit("closeRecharge");
            },
            getInvoiceInitedByUser(pageNumber) {
                this.showLoading();
                gpuHubInvoiceApi.getInvoiceInitedByUser(pageNumber, this.requestParam).then(response => {
                    if (response.data && response.data.result === 0 && response.data.data !== null) {
                        this.paymentInvoiceSelection.data = response.data.data.data;
                        this.paymentInvoiceSelection.pagingItem = response.data.data.pagingItem;
                        this.hideLoading();
                    }
                    else {
                        this.paymentInvoiceSelection.data = [];
                        this.paymentInvoiceSelection.pagingItem = this.defaultPagingItem;
                        this.hideLoading();
                        if (response.data && response.data.message !== null && response.data.message !== '') {
                            commonAct.showError(response.data.message || this.$lang.common.error);
                        }
                    }
                }).catch(error => {
                    console.error(error);
                    this.paymentInvoiceSelection.data = [];
                    this.paymentInvoiceSelection.pagingItem = this.defaultPagingItem;
                    this.hideLoading();
                    commonAct.showError(error);
                });
            },
            pageClickHandle(pageNumber) {
                this.getInvoiceInitedByUser(pageNumber);
            },
            refreshClick() {
                this.getInvoiceInitedByUser(1);
            },
            clearSelectedInvoice() {
                this.$set(this, "paymentInvoiceSelected", null);
                this.$set(this, "rechargeModel", {
                    pickedSource: "gpuHub",
                    amount: 0.0,
                    pointConversion: 0.0,
                    extraPointConversion: 0.0,
                    provider: "TESTING",
                    userId: this.detailUserItem.id,
                });
            },
            selectedInvoiced(itemSelected) {
                this.$set(this, "paymentInvoiceSelected", Object.assign(itemSelected, {}));
                this.$set(this, "rechargeModel", {
                    pickedSource: "gpuHub",
                    amount: itemSelected.amountToPay,
                    pointConversion: itemSelected.irenderPoint,
                    extraPointConversion: 0.0,
                    provider: itemSelected.paymentInstrumentType.toUpperCase(),
                    userId: this.detailUserItem.id,
                    invoiceId: itemSelected.id,
                });
            }
        }
    }
</script>